@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.container-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  margin: 4rem auto;
  @include from(m) {
    margin: 2rem auto;
  }
  @include from(l) {
    margin: 1rem auto;
  }
}

.container-404--img {
  max-width: 100%;
  height: 250px;
}
