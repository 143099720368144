@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.main-reserve-header {
  width: 100%;
  background-color: var(--blue-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-fixed);
}

.reserve-header {
  width: 100%;
  height: var(--reserveHeaderHeight);
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--blue-color);
}


// Contenedor de la campana y el boton de cta
.reserve-header--portal {
  display: flex;
}


.button-portal {
  display: none;
  @include from($reserveChangeDesktop) {
    display: block;
  }
}

.button-portal-desktop {
  display: block;
  @include from($reserveChangeDesktop) {
    display: none;
  }
}
