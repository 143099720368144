@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@300;700;900&display=swap');

// Sistema de columnas de 5% a 100%
@each $bp, $size in $breakPoints {
  @media screen and (min-width: $size) {
      @for $i from 1 through 20 {
          .#{$bp}-#{$i*5} {
            width: #{$i * 5%};
          }
      }
  }
}

// Sistema de margenes de 5px a 100px
@for $i from 1 through 20 {
  .mb-#{$i*5} {
    margin-bottom: #{$i * 5px};
  }
  .mt-#{$i*5} {
    margin-top: #{$i * 5px};
  }
  .mr-#{$i*5} {
    margin-right: #{$i * 5px};
  }
  .ml-#{$i*5} {
    margin-left: #{$i * 5px};
  }
  .pb-#{$i*5} {
    padding-bottom: #{$i * 5px};
  }
  .pt-#{$i*5} {
    padding-top: #{$i * 5px};
  }
  .pr-#{$i*5} {
    padding-right: #{$i * 5px};
  }
  .pl-#{$i*5} {
    padding-left: #{$i * 5px};
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.center-text {
    text-align: center;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

// Estilos para los titulos del dashboard.
.title-upper-red {
  text-transform: uppercase;
  // font-weight: 600;
  font-size: 1.5rem;
  color: var( --purple-color);
  font-family: 'Bebas Neue', sans-serif, cursive;
  span {
    color: white !important;
  }
}

.title-upper-blue {
  text-transform: uppercase;
  // font-weight: 600;
  font-size: 1.5rem;
  color: var(--blue-color);
  font-family: 'Bebas Neue', sans-serif, cursive;
}

.title-small-red {
  // text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
  color: var( --purple-color);
  // font-family: 'Bebas Neue', cursive;
}

.title-small-blue {
  font-weight: 600;
  font-size: 1rem;
  color: var(--blue-color);
  // font-family: 'Bebas Neue', cursive;
}

.small-text {
  font-size: var(--small-font-size);
}

.no-space {
  margin: 0;
  padding: 0;
}
.center-btn {
  display: block;
  margin-right: auto;
  margin-left: auto;
}


// Estilos para los input ( borde verde )
.input {
  border: 2px solid var(--turquoise-color) !important;
  border-radius: var(--radius-btn);
  height: 40px;
  &.small {
    height: 34px !important;
    color: var(--turquoise-color);
    font-weight: bold;
  }
}

// Estilos para los input radio
.active-radio {
  background-color: var(--gray-normal);
  border-radius: 15px;
  padding: .7rem 1rem;
  min-width: 120px;
  max-width: 160px;
  min-height: 45px;
  max-height: 45px;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &.small {
    min-width: 100px;
    max-width: 120px;
    min-height: 35px;
    max-height: 35px;
    padding: .4rem 1rem;
  }
}
.active-radio-check {
  background-color: var(--turquoise-color);
  border-radius: 15px;
  padding: .85rem 1rem;
  white-space: nowrap;
  min-width: 120px;
  max-width: 160px;
  min-height: 45px;
  max-height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &.small {
    min-width: 100px;
    max-width: 120px;
    min-height: 35px;
    max-height: 35px;
    padding: .4rem 1rem;
  }
  span {
    color: var(--gray-normal);
  }
}
.big-radio {
  min-width: 160px;
  max-width: 160px;
}


body {
  position: relative;
  font-family: 'Roboto', sans-serif;
}
// Sistema de botones - se usan variables css para que se pueda cambiar su valor en caso de que se neesite.

// Con la clase button se crea un boton con los colores principales.
// Para los otros botones solo reescribo las variables :)
.button {
  height: var(--buttonHeight);
  line-height: var(--buttonHeight);
  min-width: 100px;
  padding:  0 var(--buttonPadding);
  border-radius: var(--radius-btn);
  color: var(--color-btn);
  background-color: var(--bg-color-btn);
  border: 2px solid var(--bg-color-btn);
  font-weight: 700;
  transition: none;

  &.small {
    --buttonHeight: 30px;
    --buttonPadding: 1.5rem;
  }

  &:active, &:focus {
    box-shadow: transparent;
    background-color: var(--bg-color-btn);
    color: var(--color-btn);
    outline: none !important;
    border-color: var(--bg-color-btn); 
  }

  // Para los outlines - solo cuando tenga la clase le cambias el --bg-color-btn
  &.outline {
    background-color: transparent;
    border: 2px solid var(--bg-color-btn);
    color: var(--bg-color-btn);
    &:hover {
      color: white !important;
      background-color: var(--bg-color-btn);
      border: 2px solid var(--bg-color-btn);
    }
  }

  &:hover {
    color: white;
  }

}

.button.steelblue {
  --bg-color-btn: var(--steelblue-color);
  &:hover:not(.outline) {
    background-color: lighten($steelblue-color, 8);
    border: 2px solid lighten($steelblue-color, 8);
  }
}

.button.blue {
  --bg-color-btn: var(--blue-color);
  &:hover:not(.outline) {
    background-color: lighten($blue-color, 8);
    border: 2px solid lighten($blue-color, 8);
  }
}

.button.turquoise {
  --bg-color-btn: var(--turquoise-color);
  &:hover:not(.outline) {
    background-color: lighten($turquoise-color, 8);
    border: 2px solid lighten($turquoise-color, 8);
  }
}

.button.red {
  --bg-color-btn: var(--red-color);
  &:hover:not(.outline) {
    background-color: lighten($red-color, 8);
    border: 2px solid lighten($red-color, 8);
  }
}

.button.purple {
  --bg-color-btn: var(--purple-color);
  &:hover:not(.outline) {
    background-color: lighten($purple-color, 8);
    border: 2px solid lighten($purple-color, 8);
  }
}

.button.gray {
  --bg-color-btn: var(--gray-bold);
  &:hover:not(.outline) {
    background-color: lighten($gray-bold, 8);
    border: 2px solid lighten($gray-bold, 8);
  }
}

.button.white {
  --bg-color-btn:white;
  --color-btn: var(--blue-color);
  &:hover:not(.outline) {
    background-color: transparent;
    border: 2px solid white;
  }
  &.outline {
    &:hover {
      color: var(--blue-color) !important;
      background-color: white;
      border: 2px solid white;
    }
  }
}


.box {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  padding: 2rem 1rem;
  box-sizing: border-box !important;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-box);
  @include from(400px) {
    padding: 2rem;
  }
  @include from(990px) {
    min-width: 637px;
    max-width: 766px;
  }
  @include from(1090px) {
    min-width: 766px;
  }
}

.flex {
  display: flex;

  &.center-cross {
    align-items: center;
  }
}

.input-filter {
  height: 45px;
  width: 225px;
  display: inline-block;
  &.inline-block {
    // width: 100%;
    display: inline-block;
    input {
      height: 55px !important;
    }
  }
  &.block {
    width: 100%;
    display: block;
    input {
      height: 55px !important;
    }
  }
}
.input-filter .ant-select-selector {
  border: 2px solid var(--turquoise-color) !important;
  width: 100%;
  height: 100% !important;
  display: flex;
  align-items: center;
  border-radius: 15px !important;
  color: var(--turquoise-color) !important;
  font-weight: 600;
}

input[type=time]::-webkit-datetime-edit-ampm-field {
  display: none;
}

// Estilos collapse

.ant-collapse-header {
  color: var(--blue-color) !important;
  font-weight: bold;
  svg {
    color: var(--gray-bold) !important;
  }
}


// Estilos tabs
.ant-tabs-nav-list {
  flex-direction: column !important;
  @include from(m) {
    flex-direction: row !important;
  }
}



