@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.main-detail-sale {
  width: 100%;
  // background-color: red;
  @include from(710px) {
    display: flex;
  }
  @include from(830px) {
    display: block;
  }
  @include from(940px) {
    display: flex;
  }
}


.main-detail-sale--row-1 {
  @include from(710px) {
    padding-right: 2rem;
  }
  @include from(830px) {
    padding-right: 0;
  }
  @include from(940px) {
    padding-right: 2rem;
  }
}

.main-detail-sale--row-2 {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid var(--gray-normal);
  @include from(710px) {
    margin-top: 0;
    padding-top: 37px;
    border-top: 0;
    border-left: 1px solid var(--gray-normal);
    padding-left: 2rem;
  }
  @include from(830px) {
    margin-top: 2rem;
    padding-top: 2rem;
    padding-left: 0;
    border-top: 1px solid var(--gray-normal);
    border-left: 0;  
  }
  @include from(940px) {
    margin-top: 0;
    padding-top: 37px;
    border-top: 0;
    border-left: 1px solid var(--gray-normal);
    padding-left: 2rem;
  }
}


.message-detail-sale {
  color: var(--gray-bold);
}
