@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.item-notification {
  width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  &.unread {
   background-color: #f0f0f0;
   border-bottom: 1px solid white;
   &:hover {
    background-color: #d8d8d8;
  }
  }
  &:hover {
    background-color: #cac9c9;
    cursor: pointer;
  }
}

.item-notification--img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
  img {
    width: 70px;
    height: 70px;
  }
}

.item-notification--content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 7;
  // background-color: green;
  padding: 1rem .5rem;
}
.item-notification--message {
  margin-bottom: 5px;
}

.item-notification--date {
  width: 100%;
  height: 100%;
  flex: 7;
  color: var(--blue-color);
  font-weight: bold;
  font-size: var(--small-font-size);
  // background-color: green;
}


.button-delete-notification {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 99999999999999;
  background-color: red;
  padding: .5rem;
  color: white;
}
