@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.main-icon-bell {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
  svg {
    color: var(--turquoise-color);
    font-weight: 700;
  }
  &:hover {
    --turquoise-color: var(--steelblue-color);
  }
}

.icon-bell {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .2rem;
  svg {
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
  }
}


.count-notification {
  margin-right: .3rem;
  margin-left: .3rem;
  // background-color: blue;
}

.count-notification .ant-badge-count {
  top: 0 !important;
  right: 35px !important;
  display: flex;
  align-items: center;
  
}
.ant-scroll-number {
  color: white !important;
  font-weight: bold;
  display: flex !important;
  align-items: center !important;
}
