@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


// Reservas por rango de fecha
.reserve-for-range-start, .reserve-for-range-finish {
  display: flex;
  flex-direction: column;
}

.reserve-for-range-input-container {
  width: 100%;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  @include from(550px) {
    grid-template-columns: repeat(2, 1fr);
  }
}


input[type="date"] {
  background-color: white;
  color: var(--turquoise-color);
  font-weight: bold;
  border-radius: var(--radius-btn);
  padding: .3rem 1rem;
  outline: none;
  border: 2px solid var(--turquoise-color) !important;
}

.reserve-for-range--select {
  width: 100%;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 2;
  @include from(m) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1;
  }
}
