@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.main-consulta-socio-details-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    margin-left: 10px;
  }
}

.main-consulta-socio-details--grid {
  grid-gap: 1rem;
  @include from(930px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.main-consulta-socio-details--col-1 {
  flex: 1;
  @include from(930px) {
    padding-right: 2rem;
    border-right: 1px solid var(--gray-normal);
  }
}

.main-consulta-socio-details--col-2 {
  flex: 1;
  margin-top: 2rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
  border-top: 1px solid var(--gray-normal);
  @include from(930px) {
    margin-top: 0;
    padding-top: 0;
    padding-left: 2rem;
    border-top: none;
  }
}

