@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.create-form_shedules {
  --column: 1;
  --gap: 0;
  display: grid;
  grid-template-columns: repeat(var(--column), 1fr);
  grid-template-rows: auto;
  grid-gap: var(--gap);
  @include from(680px) {
    --column: 2;
    gap: 1rem;
  }
  @include from(830px) {
    --column: 1;
    gap: 0;
  }
  @include from(940px) {
    --column: 2;
    gap: 1rem;
  }
}

.button-schedules {
  align-self: center;
  @include from(680px) {
    grid-column: 2;
  }
  @include from(830px) {
    grid-column: 1;
  }
  @include from(940px) {
    grid-column: 2;
  } 
}

.button-create-relation {
  color: var(--gray-bold);
}

.input-time {
  border: 2px solid var(--turquoise-color) !important;
  width: 100%;
  height: 35px !important;
  display: flex;
  align-items: center;
  padding: 0 .5rem;
  border-radius: 15px !important;
  color: var(--turquoise-color) !important;
  font-weight: 600;
  &:focus-visible {
    outline: none;
  }
}
