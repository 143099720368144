@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.inner-title {
	align-items: center;
}

.inner-title-img {
	height: 75px;
	width: 75px;
}

.inner-title-item {
	line-height: 20px;
}

.inner-title--subtitle {
  font-size: var(--small-font-size);
  color: var(--purple-color);
  line-height: 15px;
  font-weight: 600;
  @include from(m) {
    font-size: var(--body-font-size);
  }
}
