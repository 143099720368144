@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.map-container {
}
.map-row {
	margin: 5px 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;  //colocar en 30, mismo height que los seats
}
.row--empty {
	height: 40px;
}