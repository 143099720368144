@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.main-profile-partner {
  --direction: column;
  min-width: 100%;
  display: flex;
  flex-direction: var(--direction);
  @include from(670px) {
    --direction: row;
  }
  @include from(830px) {
    --direction: column;
  }
  @include from(915px) {
    --direction: row;
  }
}


.main-profile-partner--parte-1 {
  // background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 4;

  @include from(670px) {
    padding-right: 1.5rem;
    border-right: 1px solid var(--gray-normal);
  }
  @include from(830px) {
    padding-right: 0;
    border-right: none;
  }
  @include from(915px) {
    padding-right: 1.5rem;
    border-right: 1px solid var(--gray-normal);
  }
}

// icono de la camare de la imagen previsualizada 
.main-profile-partner--icon-camera {
  width: 36px;
  height: 36px;
  position: absolute;
  top: -5px;
  right: -10px;
  cursor: pointer;
  background-color: var(--gray-normal);
  padding: .5rem;
  border-radius: 50%;
  svg {
    color: var(--turquoise-color);
    font-size: 1.3rem;
    transition: font-size .3s;
    &:hover {
      color: var(--steelblue-color);
      font-size: 1.2rem;
      transition: font-size .3s;
    }

  }
}


.main-profile-partner--img-container {
  position: relative;
  width: 130px;
  height: 130px;
  display: block;
  border-radius: 50%;
  border: 1px solid var(--turquoise-color);
  // padding: .5rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 40%; 
    border-radius: 50%;
  }
}


.main-profile-partner--parte-2 {
  flex: 6;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--gray-normal);
  .container-button {
    text-align: center;
  }
  @include from(670px) {
    padding-right: 2rem;
    padding-left: 2rem;
    border-top: none;
  }
  @include from(830px) {
    border-top: 1px solid var(--gray-normal);
    padding-right: 0;
    padding-left: 0;
  }
  @include from(915px) {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
    padding-right: 2rem;
    padding-left: 2rem;
    .container-button {
      text-align: left;
    }
  }
  @include from(l) {
    padding-right: 0;
  }

  div:not(:last-child) {
    border-bottom: 1px solid var(--gray-normal);
  }
  div:nth-child(1),  div:nth-child(2){
   padding-bottom: 15px;
  }
} 


// Oculto es input para personalizarlo
.input-file {
  display: none !important;
}

.label-file-user {
  cursor: pointer;
  width: 120px !important;
  padding: 0 1rem !important;
  text-align: center;
}

.toggle-rol {
  text-decoration: underline;
  margin-top: 5px;
  color: var(--blue-color);
  cursor: pointer;
  display: inline-block;
}

// Formulario cambiar rol 
.form-change-role {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  div {
    border: none !important;
    padding-bottom: 0 !important;
  }
}
