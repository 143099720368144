@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.reserve-login-admin {
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.reserve-login-admin--header {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  height : 100px;
  display: flex;
  padding: .5rem 1rem;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: var(--z-normal);
}

.reserve-login-admin--form {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  margin: 3rem auto;
  background-color: white;
  box-shadow: var(--box-shadow);
  padding:3rem 2rem;
  border-radius: var(--border-radius-box);
  z-index: var(--z-normal);
  .ant-form-item {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ant-form-item-control {
      width: 100% !important;

      .ant-input,  .ant-input-affix-wrapper {
        border-radius: var(--border-radius-box) !important;
      }

    }

  }
}

.reserve-login-admin--title {
  color: var(--blue-color);
  font-weight: 600;
  font-size: var(--subtitle-font-size); 
}

.reserve-login-admin--subtitle {
  color: var(--gray-semibold);
  font-size: var(--body-font-size); 
}

.reserve-login-admin--links {
  text-align: center;
  color: var(--steelblue-color);
}

// FONDO
.background-vaac {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  padding-top: 250px;
  padding-bottom: 250px;
  background-image: url('../../../assets/vaac-fondo.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  z-index: var(--z-normal);
  clip-path: polygon(0 0, 100% 0%, 100% 68%, 0 91%);
  .capa {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: rgba(7,171,177,.596078);
    width: 100%;
    height: 100%;
    z-index: 10; 
  }
}

.capa-gris {
  width: 100%;
  height: 300px;
  position: absolute;
  top: 170px;
  background-color: var(--gray-normal);
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
  z-index: var(--z-back) !important;
}

.btn-login {
  svg {
    color: var(--orange-color);
  }
}

.message-error {
  color: var(--red-color);
  &.form {
    margin-top: -1rem;
  }
}

.recaptcha-login-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .recaptcha-login {
    display: none;
    @include from(400px) {
      display: block;
    }
  }
  .recaptcha-login-small {
    display: block;
    @include from(400px) {
      display: none;
    }
  }
}
