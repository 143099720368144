@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.modal-body-reserves {
  text-align: center;
}
.ant-modal-content {
  width: 95% !important;
  border-radius: 20px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 490px !important;
}

.modal-body-reserves-message {
  margin-bottom: .5rem;
  color: var(--purple-color);
  span {
    font-weight: 600;
  }
}

.main-modal-body-reserves--logo {
  height: 76px;
}
.modal-body-reserves-link {
  text-decoration: underline;
  color: var(--steelblue-color);
}
