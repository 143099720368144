@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.container-errorSelect {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 1rem;
  span {
    width: 100%;
    color: var(--red-color);
    padding: .25rem 1rem;
    border: 2px solid var(--turquoise-color) !important;
    border-radius: var(--radius-btn);
    white-space: nowrap;
    // height: 30px;
  }
}
