@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.detail-reserve-row-2 {
  // background-color: blue;
  border-top: 1px solid var(--gray-normal);
  padding-top: 1rem;
  border-bottom: 1px solid var(--gray-normal);
  padding-bottom: 1rem;
  @include from(990px) {
    display: flex;
    flex-direction: column;
  }
}

.detail-reserve-row-2--datails {
  // border-bottom: 1px solid var(--gray-normal);
  padding-bottom: 1rem;
  flex: 1;
  @include from(990px) {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.detail-reserve-row-2--participants {
  // background-color: yellow;
  flex: 2;
  padding-top: 1rem;
  border-top: 1px solid var(--gray-normal);
  margin-top: 20px;
}
