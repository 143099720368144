@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.reserve-qualify-list {
  width: 100%;
  // background-color: red;
  text-align: left;
}

.reserve-qualify-list--item {
  @include from(530px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }
  text-align: center;
  border-bottom: 1px solid var(--gray-normal);
  padding: 1rem 0;
}

.reserve-qualify-list--title {
  display: block;
}
 // Container de los 2 inputs radio
 .main-container-input-radio {
  margin-top: .8rem;
  @include from(530px) {
    margin-top: 0;
  }
}

 // Container de cada input radio
.container-input-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .2rem
}
