@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.main-detail-activity {
  width: 100%;
  padding-top: 1rem;
  border-top: 1px solid var(--gray-normal);
}

.main-detail-activity-status {
 
  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 25px;
  }
}

.main-detail-activity-cupos {
  padding-top: 1rem;
  border-top: 1px solid var(--gray-normal);
}
