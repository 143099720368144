.container-verify-acount {
  width: 100%;
  min-height: 100vh;
}
.verify-acount{
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
