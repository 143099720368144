@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.main-reserve-footer {
  width: 100%;
  background-color: var(--turquoise-color);
  color: white;
  align-self: flex-end;
}

.reserve-footer {
  width: 100%;
  height: 100%;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  padding: 4rem 1rem 2rem;
}


.reserve-footer-info {
  width: 100%;
  display: flex;
  justify-content: center;
  @include from($reserveChangeDesktop) {
    justify-content: space-between;
  }
  align-items: flex-start;
  flex-wrap: wrap;
}


.reserve-footer-main {
  width: 100%;
  text-align: left;
  line-height: .55rem;
  margin-bottom: 30px;
  @include from($reserveChangeDesktop) {
    width: 33.33%;
    text-align: left;
    margin-bottom: 0;
  }

  img {
    margin-right: 5px;
    margin-top: 1px;
  }
  
  .reserve-footer-main-data {
    line-height: .95rem;
    margin-bottom: .5rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @include from($reserveChangeDesktop) {
      justify-content: flex-start;
    }
  }
}

.reserve-footer-logo {
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  @include from(490px) {
    width: 50%;
    margin-bottom: 0;
  }
  @include from($reserveChangeDesktop) {
    width: 33.33%;
    text-align: right;
    justify-content: flex-end;
  }
}

.reserve-footer-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @include from(490px) {
    width: 50%;
  }
  @include from($reserveChangeDesktop) {
    width: 33.33%;
    text-align: right;
    justify-content: flex-end;
  }
  a {
    width: 200px;
    // height: 40px;
    // line-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 7px;
    }
  }
}


.reserve-footer-social {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  @include from($reserveChangeDesktop) {
    margin-top: 0;
  }
}

.reserve-footer-copy {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin-bottom: 0;
    line-height: .95rem;
  }
}
