@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.reserve-active-table .ant-table-thead .ant-table-cell {
	color: var(--blue-color) !important;
	font-weight: 600 !important;
	background: white;
}
.bg-light {
	background-color: var(--blue-light) !important;
}
.reserve-active-table {
	margin: 15px auto;
	@include to(1050px) {
		width: 500px;
	}
	@include to(830px) {
		width: 700px;
	}
	@include to(670px) {
		width: 450px;
	}
	@include to(500px) {
		width: 330px;
	}
	@include to(330px) {
		width: 210px;
	}
}
