@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

// .create-admin--form {
//   --column: 1;
//   --gap: 0;
//   display: grid;
//   grid-template-columns: repeat(var(--column), 1fr);
//   grid-template-rows: auto;
//   grid-gap: var(--gap);
//   @include from(680px) {
//     --column: 2;
//     gap: 1rem;
//   }
//   @include from(830px) {
//     --column: 1;
//     gap: 0;
//   }
//   @include from(940px) {
//     --column: 2;
//     gap: 1rem;
//   }
// }

.create-admin--btn {
  text-align: center;
  @include from(680px) {
    grid-column: 2 / 3;
    text-align: right;
  }
  @include from(830px) {
    grid-column: 1;
    text-align: center;
  }
  @include from(940px) {
    grid-column: 2 / 3;
    text-align: right;
  }
}
