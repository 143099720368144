@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.main-profile-instuctor--parte-2 {
  flex: 6;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--gray-normal);
  .container-button {
    text-align: center;
  }
  @include from(670px) {
    padding-right: 2rem;
    padding-left: 2rem;
    border-top: none;
  }
  @include from(830px) {
    border-top: 1px solid var(--gray-normal);
    padding-right: 0;
    padding-left: 0;
  }
  @include from(915px) {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
    padding-right: 2rem;
    padding-left: 2rem;
    .container-button {
      text-align: left;
    }
  }
  @include from(l) {
    padding-right: 0;
  }

}

.create-picture--instuctor  {
  padding: 1rem;
}
