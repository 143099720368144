@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.modal-reserve-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: rgba(0,0,0,.75);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  opacity: 0;
  visibility: hidden;
  z-index: var(--z-modal);
  &.open {
    opacity: 1;
    visibility: visible;
    display: grid;
    justify-content: center;
    align-items: center;
    transition: all .5s;
  }
}

.modal-reserve {
  width: 100%;
  z-index: var(--z-modal);
}
