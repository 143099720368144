@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.main-consulta-socio-form {
  width: 100%;
  // height: 200px;
  // background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-consulta-socio-list {
  width: 100%;

}
