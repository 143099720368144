@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.ant-drawer-body {
  padding: 0 !important;
  // background-color: red;

  &::-webkit-scrollbar {
    width: 8px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
    // display: none;  /* Ocultar scroll */
  }

  &::-webkit-scrollbar-thumb {
    background: var(--turquoise-color);
    border-radius: 4px;
  }  

  /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
  &::-webkit-scrollbar-thumb:hover {
      background: var(--turquoise-color);
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  /* Cambiamos el fondo cuando esté en active */
  &::-webkit-scrollbar-thumb:active {
      background-color: var(--turquoise-color);
  }

  /* Ponemos un color de fondo y redondeamos las esquinas del track */
  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 4px;
  }

  /* Cambiamos el fondo cuando esté en active o hover */
  &::-webkit-scrollbar-track:hover,
  &::-webkit-scrollbar-track:active {
    background-color: var(--blue-color);
  }
}
