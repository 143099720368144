.spinner_loader {
  width: 100%;
  min-height: 100vh;
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  // display: block;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // min-height: 100vh;
  }
  &.small {
    min-height: 50px;
    // max-height: 50px;
  }
  &.small_2 {
    min-height: 50px;
    max-height: 50px;
  }
}
