@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.toggle-menu-dashboard {
  cursor: pointer;
  background-color: transparent;
  padding: .5rem .8rem;
  height: auto;
  border-radius: 5px;
  color: var(--turquoise-color);
  border: 2px solid;
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  svg {
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  @include from($reserveChangeDesktop) {
    display: none;
  }
}


.drawer-dashboard .ant-drawer-body {
  padding: 0 !important;
}

.ant-drawer-header-no-title .ant-drawer-close {
  svg {
    color: var(--turquoise-color);
  }
}
