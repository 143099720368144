@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.separador {
  width: 100%;
  height: 2rem;
  margin-bottom: 1rem;
}

.container-reserve-main-title {
	position: absolute;
	top: 1rem;
	left: 1rem;
}

.container-reserve-main-title--title {
  color: var(--blue-color);
  margin-bottom: 0;
}

.container-reserve-main-title--breadcrumb {
  color: var(--turquoise-color);
  margin-bottom: 0;
  font-weight: 600;
}
